<template>
  <v-container fluid fill-height>
    <v-layout justify-center>
      <v-flex xs12 sm8>
        <v-card class="elevation-12 default-border">
          <v-toolbar color="secondary">
            <v-toolbar-title>{{
              this.$route.params.id
                ? "Edição de Cartão " + cadastroModel.idCartao
                : "Cadastro de Cartão"
            }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form v-model="formularioCadastroValido" ref="formularioCadastroRef">
              <v-container grid-list-lg>
                <v-layout row wrap>
                  <v-flex xs12 md2>
                    <v-switch :label="`${cadastroModel.ativo ? 'Ativo' : 'Inativo'}`" v-model="cadastroModel.ativo"
                      color="primary"></v-switch>
                  </v-flex>
                  <v-flex xs12 md6>
                    <v-text-field v-model="cadastroModel.email" color="secondary" :rules="regras.email"
                      prepend-icon="mail" label="E-mail" type="text" required></v-text-field>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn id="voltar" color="primary" class="grey--text text--darken-1" :to="{ name: 'Cartoes' }" text>Voltar
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn id="finalizarFecha" color="secondary" class="default-border" depressed @click="finalizar()"
              :disabled="disableBtnFinalizar || !formValid">Salvar</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
    <v-snackbar :timeout="5000" :color="`${snackbarModel.sucesso ? 'success' : 'error'}`" bottom multi-line
      v-model="snackbarModel.mostrar">
      {{ snackbarModel.text }}
      <v-btn text dark @click.native="
              `${snackbarModel.sucesso
                ? go('Cartoes')
                : (snackbarModel.mostrar = false)
              }`
              ">Fechar</v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import customFormat from "@/helpers/custom-format.js";
import cipher from "@/helpers/cipher.js";
import jwt from "@/helpers/jwt";

export default {
  name: "CadastroCartao",
  data: () => ({
    formularioCadastroValido: false,
    disableBtnFinalizar: false,
    precoFormatado: 0.0,
    cadastroModel: {
      idCartao: null,
      ativo: true,
      email: null
    },
    snackbarModel: {
      mostrar: false,
      text: "",
      sucesso: false,
    },
    regras: {
      branco: [(v) => !!v || "Você não pode deixar este campo em branco"]
    }
  }),
  computed: {
    formValid: function () {
      return this.formularioCadastroValido;
    },
    customFormatComputed: function () {
      return customFormat;
    },
  },
  mounted: function () {
    this.buscarCartao();
  },
  methods: {
    buscarCartao: function () {
      const self = this;
      const item = jwt.decryptToken();
      if (item != null) {
        if (
          this.$route.params.id &&
          cipher.decrypt(this.$route.params.id)
        ) {
          self.$store.commit("startLoading");
          self.$http
            .get(
              `${process.env.VUE_APP_URL_API}/cartao/busca/` +
              cipher.decrypt(this.$route.params.id),
              {
                headers: {
                  Authorization:
                    `Bearer ${localStorage.getItem("token")}`,
                },
              }
            )
            .then(function (response) {
              const resposta = response.data[0];
              self.cadastroModel = resposta
              self.$store.commit("endLoading");
            }).catch(function (error) {
              self.$store.commit("endLoading");
              console.log(error);
              self.snackbarModel.text = error.response.data.mensagem;
              self.snackbarModel.sucesso = false;
              self.snackbarModel.mostrar = true;
              self.disableBtnFinalizar = false;
            });
        }
      }
    },
    finalizar() {
      const self = this;
      self.$store.commit("startLoading");
      if (self.$refs.formularioCadastroRef.validate()) {
        const item = jwt.decryptToken();
        if (item != null) {
          if (!self.$route.params.id) {
            self.$http
              .post(
                `${process.env.VUE_APP_URL_API}/cartao/cadastro`,
                self.cadastroModel,
                {
                  headers: {
                    Authorization:
                      `Bearer ${localStorage.getItem("token")}`,
                  },
                }
              )
              .then(function (response) {
                const data = response.data;
                if (data.sucesso) {
                  self.disableBtnFinalizar = data.sucesso;

                  self.go("Cartoes", {
                    snackbarText: data.mensagem,
                    snackbarMostrar: true,
                    snackbarSucesso: data.sucesso,
                  });
                } else {
                  self.snackbarModel.text = data.mensagem;
                  self.snackbarModel.mostrar = true;
                  self.snackbarModel.sucesso = data.sucesso;
                  self.disableBtnFinalizar = data.sucesso;
                }
              })
              .catch(function (error) {
                self.snackbarModel.text = error.response.data.mensagem;
                self.snackbarModel.sucesso = false;
                self.snackbarModel.mostrar = true;
                self.disableBtnFinalizar = false;
              });
          } else {
            self.cadastroModel.idCartao = cipher.decrypt(
              self.$route.params.id
            );
            self.$http
              .post(
                `${process.env.VUE_APP_URL_API}/cartao/edicao`,
                self.cadastroModel,
                {
                  headers: {
                    Authorization:
                      `Bearer ${localStorage.getItem("token")}`,
                  },
                }
              )
              .then(function (response) {
                const data = response.data;
                if (data.sucesso) {
                  self.disableBtnFinalizar = data.sucesso;
                  self.go("Cartoes", {
                    snackbarText: data.mensagem,
                    snackbarMostrar: true,
                    snackbarSucesso: data.sucesso,
                  });
                } else {
                  self.snackbarModel.text = data.mensagem;
                  self.snackbarModel.mostrar = true;
                  self.snackbarModel.sucesso = data.sucesso;
                  self.disableBtnFinalizar = data.sucesso;
                }
              })
              .catch(function (error) {
                self.snackbarModel.text = error.response.data.mensagem;
                self.snackbarModel.sucesso = false;
                self.snackbarModel.mostrar = true;
                self.disableBtnFinalizar = false;
              });
          }
        } else {
          self.go("Login");
        }
      }
      self.$store.commit("endLoading");
    },
    go: function (routeName, routeParams) {
      const self = this;
      if (this.$router.currentRoute.name != routeName) {
        if (!routeParams) {
          self.$router.push({ name: routeName });
        } else {
          self.$router.push({ name: routeName, params: routeParams });
        }
      }
    },
  },
};
</script>