import Vue from "vue";
import Router from "vue-router";
import Controller from "./controller.js";
import EsqueciSenha from "@/components/autenticacao/EsqueciSenha";
import Login from "@/components/autenticacao/Login";
import RecuperarSenha from "@/components/autenticacao/RecuperarSenha";
import Portal from "@/components/portal/Portal";
import ErroPaginaNaoEncontrada from "@/components/paginas-erro/ErroPaginaNaoEncontrada";
import Usuarios from "@/components/usuarios/Usuarios";
import CadastroUsuario from "@/components/usuarios/CadastroUsuario";
import Cartoes from "@/components/cartoes/Cartoes";
import CadastroCartao from "@/components/cartoes/CadastroCartao";
import ResgateCartao from "@/components/cartoes/ResgateCartao";
import Invoices from "@/components/invoice/Invoices";
import CadastroInvoicePrivado from "@/components/invoice/CadastroInvoicePrivado";
import Empresas from "@/components/empresas/Empresas";
import CadastroEmpresa from "@/components/empresas/CadastroEmpresa";


Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: "/login",
      name: "Login",
      component: Login,
      meta: { requiresAuth: false, mostraSideMenu: false }
    },
    {
      path: "/",
      name: "Portal",
      alias: "/portal",
      component: Portal,
      meta: { requiresAuth: true, mostraSideMenu: true }
    },
    {
      path: "/usuarios",
      name: "Usuarios",
      component: Usuarios,
      meta: { requiresAuth: true, mostraSideMenu: true }
    },
    {
      path: "/usuario/cadastro",
      name: "CadastroUsuario",
      component: CadastroUsuario,
      meta: { requiresAuth: true, mostraSideMenu: true }
    },
    {
      path: "/empresas",
      name: "Empresas",
      component: Empresas,
      meta: { requiresAuth: true, mostraSideMenu: true }
    },
    {
      path: "/empresa/cadastro",
      name: "CadastroEmpresa",
      component: CadastroEmpresa,
      meta: { requiresAuth: true, mostraSideMenu: true }
    },
    {
      path: '/cartoes',
      name: 'Cartoes',
      component: Cartoes,
      meta: { requiresAuth: true, mostraSideMenu: true }
    },
    {
      path: '/cartoes/cadastro',
      name: 'CadastroCartao',
      component: CadastroCartao,
      meta: { requiresAuth: true, mostraSideMenu: true }
    },
    {
      path: '/invoices',
      name: 'Invoices',
      component: Invoices,
      meta: { requiresAuth: true, mostraSideMenu: true }
    },
    {
      path: '/cadastro/privado',
      name: 'CadastroInvoicePrivado',
      component: CadastroInvoicePrivado,
      meta: { requiresAuth: true, mostraSideMenu: true }
    },
    {
      path: '/cartoes/resgate/:id?',
      alias: '/resgate/:id',
      name: 'ResgateCartao',
      component: ResgateCartao,
      meta: {
        title: "Resgate de Cartão",
        navbartitle: "Resgate de Cartão", requiresAuth: false, mostraSideMenu: false
      }
    },
    {
      path: "/RecuperarSenha/:token",
      name: "RecuperarSenha",
      meta: {
        title: "Recuperação de Senha",
        navbartitle: "Recuperação de Senha",
        requiresAuth: false
      },
      component: RecuperarSenha
    },
    {
      path: "/EsqueciSenha",
      name: "EsqueciSenha",
      meta: {
        title: "Recuperação de Senha",
        navbartitle: "Recuperação de Senha",
        requiresAuth: false,
        hideLogin: true
      },
      component: EsqueciSenha
    },
    {
      path: "*",
      name: "ErroPaginaNaoEncontrada",
      component: ErroPaginaNaoEncontrada
    },
  ],
  mode: "history"
});

router.beforeEach((to, from, next) => {
  Controller.auth(to, from, next);
  Controller.permissaoAcesso(to, from, next);

  document.title = `QRDindin - ${to.meta.navbartitle ?? to.name}`;
});

export default router;
