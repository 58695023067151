import perfil from '@/helpers/perfil.js';
import usuarioHelper from '@/helpers/usuario.js';

export default {
  Home: {
    acesso: true
  },
  Login: {
    acesso: true
  },
  ErroPaginaNaoEncontrada: {
    acesso: true
  },
  SideMenu: {
    acesso: usuarioHelper.USUARIO_LOGADO().idtipousuario >= perfil.ID.administrador
  },
  RecuperarSenha: {
    acesso: true
  },
  EsqueciSenha: {
    acesso: true
  },
  Portal: {
    acesso: usuarioHelper.USUARIO_LOGADO().idtipousuario >= perfil.ID.administrador
  },
  Usuarios: {
    acesso: usuarioHelper.USUARIO_LOGADO().idtipousuario == perfil.ID.administrador || usuarioHelper.USUARIO_LOGADO().idtipousuario == perfil.ID.cliente
  },
  CadastroUsuario: {
    acesso: usuarioHelper.USUARIO_LOGADO().idtipousuario == perfil.ID.administrador || usuarioHelper.USUARIO_LOGADO().idtipousuario == perfil.ID.cliente
  },
  Cartoes: {
    acesso: usuarioHelper.USUARIO_LOGADO().idtipousuario == perfil.ID.administrador || usuarioHelper.USUARIO_LOGADO().idtipousuario == perfil.ID.cliente
  },
  CadastroCartao: {
    acesso: usuarioHelper.USUARIO_LOGADO().idtipousuario == perfil.ID.administrador || usuarioHelper.USUARIO_LOGADO().idtipousuario == perfil.ID.cliente
  },
  ResgateCartao: {
    acesso: true
  },
  Invoices: {
    acesso: usuarioHelper.USUARIO_LOGADO().idtipousuario == perfil.ID.administrador || usuarioHelper.USUARIO_LOGADO().idtipousuario == perfil.ID.cliente
  },
  CadastroInvoicePrivado: {
    acesso: usuarioHelper.USUARIO_LOGADO().idtipousuario == perfil.ID.administrador || usuarioHelper.USUARIO_LOGADO().idtipousuario == perfil.ID.cliente
  },
  Empresas: {
    acesso: usuarioHelper.USUARIO_LOGADO().idtipousuario == perfil.ID.administrador
  },
  CadastroEmpresa: {
    acesso: usuarioHelper.USUARIO_LOGADO().idtipousuario == perfil.ID.administrador
  },
}
