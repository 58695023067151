<template>
  <v-container fluid fill-height>
    <v-layout justify-center>
      <v-flex xs12 sm8>
        <v-card class="elevation-12 default-border">
          <v-toolbar color="secondary">
            <v-toolbar-title>{{
              this.$route.params.id
                ? "Edição de Invoice " + cadastroModel.idInvoice
                : "Cadastro de Invoice"
            }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form v-model="formularioCadastroValido" ref="formularioCadastroRef">
              <v-container grid-list-lg>
                <v-layout row wrap>
                  <v-flex xs12 md4>
                    <v-text-field ref="txtprecoFormatado" v-model="precoFormatado" color="secondary"
                      prepend-icon="attach_money" label="Valor" required v-money="money" :rules="regras.valor"
                      :disabled="disableEdicao">
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12 md4>
                    <v-combobox v-model="cadastroModel.tipoPagamentoTaxa" :items="listaTipoPagamentoTaxa" label="Tarifa"
                      prepend-icon="mdi-cash-sync" item-text="nome" required return-object
                      :disabled="disableEdicao"></v-combobox>
                  </v-flex>
                  <v-flex xs12 md4>
                    <v-switch :label="`${cadastroModel.ativo ? 'Ativo' : 'Inativo'}`" v-model="cadastroModel.ativo"
                      color="primary"></v-switch>
                  </v-flex>
                  <v-flex xs12> <v-divider class="mx-4"></v-divider></v-flex>
                  <v-flex xs12 md6>
                    <v-text-field v-model="cadastroModel.email" color="secondary" :rules="regras.email"
                      prepend-icon="mail" label="E-mail" type="text" required :disabled="disableEdicao"></v-text-field>
                  </v-flex>
                  <v-flex xs12 md6>
                    <v-text-field v-model="cadastroModel.cartao.idCartao" color="secondary" prepend-icon="mdi-qrcode"
                      label="Codigo Cartão" type="text" id="idCartao" name="idCartao" hint="Se deixar em branco, será criado um novo cartão. Se preencher, o cartão será vinculado."
                      :disabled="disableEdicao"></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-textarea v-model="cadastroModel.descricaoPix" color="secondary" clearable counter
                      clear-icon="mdi-close-circle" label="Qual a descrição do invoice ? isso será enviado no pix..."
                      type="text" required :rules="regras.descricao">
                    </v-textarea>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn id="voltar" color="primary" class="grey--text text--darken-1" :to="{ name: 'Invoices' }" text>Voltar
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn id="finalizarFecha" color="secondary" class="default-border" depressed @click="finalizar()"
              :disabled="disableBtnFinalizar || !formValid">Salvar</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
    <v-snackbar :timeout="5000" :color="`${snackbarModel.sucesso ? 'success' : 'error'}`" bottom multi-line
      v-model="snackbarModel.mostrar">
      {{ snackbarModel.text }}
      <v-btn text dark @click.native="
              `${snackbarModel.sucesso
                ? go('Invoices')
                : (snackbarModel.mostrar = false)
              }`
              ">Fechar</v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import customFormat from "@/helpers/custom-format.js";
import { VMoney } from 'v-money'
import cipher from "@/helpers/cipher.js";
import jwt from "@/helpers/jwt";

export default {
  name: "CadastroInvoicePrivado",
  data: () => ({
    formularioCadastroValido: false,
    disableBtnFinalizar: false,
    precoFormatado: 0.0,
    disableEdicao: false,
    cadastroModel: {
      idInvoice: null,
      valor: 0.0,
      email: null,
      tipoPagamentoTaxa: null,
      cartao: { idCartao: null },
      ativo: true
    },
    snackbarModel: {
      mostrar: false,
      text: "",
      sucesso: false,
    },
    listaTipoPagamentoTaxa: [],
    regras: {
      branco: [(v) => !!v || "Você não pode deixar este campo em branco"],
      valor: [(v) => !!v || "Você não pode deixar este campo em branco"],
      descricao: [v => !!v || "Você não pode deixar este campo em branco",
      v => (v && v.length <= 140) || 'Maximo de 140 characteres'],
      email: [
        (v) => !!v || "Você não pode deixar este campo em branco",
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "Você precisa informar um e-mail válido",
      ],
    },
    money: {
      decimal: ',',
      thousands: '.',
      prefix: 'R$ ',
      precision: 2,
      masked: false
    },
  }),
  computed: {
    formValid: function () {
      return this.formularioCadastroValido;
    },
    customFormatComputed: function () {
      return customFormat;
    },
  },
  mounted: function () {
    this.bindTipoTaxaPagamento();
    this.buscarInvoice();
  },
  watch: {
    precoFormatado: function (val) {
      this.cadastroModel.valor = customFormat.removeMoneyFormat(val);
    },
  },
  directives: { money: VMoney },
  methods: {
    bindTipoTaxaPagamento() {
      const self = this;
      self.listaTipoPagamentoTaxa.push({
        idTipoPagamentoTaxa: 1,
        nome: "Pre-Pago",
      })
      self.listaTipoPagamentoTaxa.push({
        idTipoPagamentoTaxa: 2,
        nome: "Pos-Pago",
      })
    },
    buscarInvoice() {
      const self = this;
      const item = jwt.decryptToken();
      if (item != null) {
        if (
          this.$route.params.id &&
          cipher.decrypt(this.$route.params.id)
        ) {
          self.disableEdicao = true
          self.$store.commit("startLoading")
          self.$http
            .get(
              `${process.env.VUE_APP_URL_API}/invoice/busca/` +
              cipher.decrypt(this.$route.params.id),
              {
                headers: {
                  Authorization:
                    `Bearer ${localStorage.getItem("token")}`,
                },
              }
            )
            .then(function (response) {
              const resposta = response.data[0];

              self.cadastroModel = resposta
              self.cadastroModel.email = resposta.cartao.email
              self.cadastroModel.email = resposta.cartao.email
              self.$refs.txtprecoFormatado.$el.getElementsByTagName(
                "input"
              )[0].value = ("R$ " + resposta.valorUsavel).replace(".", ",");
              self.$store.commit("endLoading");
            }).catch(function (error) {
              self.$store.commit("endLoading");
              self.snackbarModel.text = error.response.data.mensagem;
              self.snackbarModel.sucesso = false;
              self.snackbarModel.mostrar = true;
              self.disableBtnFinalizar = false;

            });
        }
      }
    },
    finalizar() {
      const self = this;
      if (self.$refs.formularioCadastroRef.validate()) {
        const item = jwt.decryptToken();
        if (item != null) {
          self.$store.commit("startLoading");
          if (!self.$route.params.id) {
            self.$http
              .post(
                `${process.env.VUE_APP_URL_API}/invoice/cadastro/privado`,
                self.cadastroModel,
                {
                  headers: {
                    Authorization:
                      `Bearer ${localStorage.getItem("token")}`,
                  },
                }
              )
              .then(function (response) {
                const data = response.data;
                self.$store.commit("endLoading");
                if (data.sucesso) {
                  self.disableBtnFinalizar = data.sucesso;

                  self.go("Invoices", {
                    snackbarText: data.mensagem,
                    snackbarMostrar: true,
                    snackbarSucesso: data.sucesso,
                  });
                } else {
                  self.snackbarModel.text = data.mensagem;
                  self.snackbarModel.mostrar = true;
                  self.snackbarModel.sucesso = data.sucesso;
                  self.disableBtnFinalizar = data.sucesso;
                }
              })
              .catch(function (error) {
                self.$store.commit("endLoading");
                console.log(error);
                self.snackbarModel.text = error.response.data.mensagem;
                self.snackbarModel.sucesso = false;
                self.snackbarModel.mostrar = true;
                self.disableBtnFinalizar = false;
              });
          } else {
            self.cadastroModel.idCartao = cipher.decrypt(
              self.$route.params.id
            );
            self.$http
              .post(
                `${process.env.VUE_APP_URL_API}/invoice/edicao/privado`,
                self.cadastroModel,
                {
                  headers: {
                    Authorization:
                      `Bearer ${localStorage.getItem("token")}`,
                  },
                }
              )
              .then(function (response) {
                const data = response.data;
                self.$store.commit("endLoading");
                if (data.sucesso) {
                  self.disableBtnFinalizar = data.sucesso;
                  self.go("Invoices", {
                    snackbarText: data.mensagem,
                    snackbarMostrar: true,
                    snackbarSucesso: data.sucesso,
                  });
                } else {
                  self.snackbarModel.text = data.mensagem;
                  self.snackbarModel.mostrar = true;
                  self.snackbarModel.sucesso = data.sucesso;
                  self.disableBtnFinalizar = data.sucesso;
                }
              })
              .catch(function (error) {
                self.$store.commit("endLoading");
                self.snackbarModel.text = error.response.data.mensagem;
                self.snackbarModel.sucesso = false;
                self.snackbarModel.mostrar = true;
                self.disableBtnFinalizar = false;
              });
          }
        } else {
          self.go("Login");
        }
      }
    },
    go: function (routeName, routeParams) {
      const self = this;
      if (this.$router.currentRoute.name != routeName) {
        if (!routeParams) {
          self.$router.push({ name: routeName });
        } else {
          self.$router.push({ name: routeName, params: routeParams });
        }
      }
    },
  },
};
</script>