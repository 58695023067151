<template>
  <v-navigation-drawer v-model="menuLateral" temporary fixed clipped app>
    <v-layout class="mt-3" wrap justify-center align-center>
      <v-flex xs4>
        <v-card dark width="100">
          <v-img height="100" :src="require('@/assets/img/default-user.png')"></v-img>
        </v-card>
      </v-flex>
      <v-flex xs12 class="text-center">
        <span class="headline">{{ usuarioLogado.nome }}</span>
        <br />
        <span>Empresa: {{ usuarioLogado.nomeempresa }}</span>
      </v-flex>
    </v-layout>

    <v-list>
      <v-list-item @click="go('Cartoes')" v-if="true">
        <v-list-item-action>
          <v-icon color="primary">mdi-card-bulleted</v-icon>
        </v-list-item-action>
        <v-list-item-title>Cartões</v-list-item-title>
      </v-list-item>
      
      <v-list-item @click="go('Invoices')" v-if="true">
        <v-list-item-action>
          <v-icon color="primary">mdi-invoice-clock-outline</v-icon>
        </v-list-item-action>
        <v-list-item-title>Invoices</v-list-item-title>
      </v-list-item>

      <v-list-group no-action sub-group
        v-if="usuarioLogado.idtipousuario == perfilUsr.ID.administrador || usuarioLogado.idtipousuario == perfilUsr.ID.cliente">
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>Configuração de sistema</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item @click="go('Usuarios')"
          v-if="usuarioLogado.idtipousuario == perfilUsr.ID.administrador || usuarioLogado.idtipousuario == perfilUsr.ID.cliente">
          <v-list-item-action>
            <v-icon color="primary">group</v-icon>
          </v-list-item-action>
          <v-list-item-title>Usuários</v-list-item-title>
        </v-list-item>

        <v-list-item @click="go('Empresas')" v-if="usuarioLogado.idtipousuario == perfilUsr.ID.administrador">
          <v-list-item-action>
            <v-icon color="primary">account_balance</v-icon>
          </v-list-item-action>
          <v-list-item-title>Empresas</v-list-item-title>
        </v-list-item>
      </v-list-group>
      <v-list-item @click="fazerLogout">
        <v-list-item-action>
          <v-icon color="primary">exit_to_app</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Sair</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import jwt from "@/helpers/jwt";
import usuarioHelper from "@/helpers/usuario.js";
import cipher from "@/helpers/cipher.js";
import perfil from "@/helpers/perfil.js";

export default {
  name: "SideMenu",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      menuLateral: false,
      getCurrentUser() {
        var token = jwt.decryptToken();
        if (!token) {
          localStorage.removeItem("token");
          this.$router.push("/login");
        }
        return token;
      },
    };
  },
  computed: {
    perfilUsr: function () {
      return perfil;
    },
    usuarioLogado: function () {
      return usuarioHelper.USUARIO_LOGADO();
    },
    cipherComputed: function () {
      return cipher;
    },
  },
  watch: {
    value: function (newValue) {
      this.menuLateral = newValue;
    },
    menuLateral: function (newValue) {
      this.$emit("input", newValue);
    },
  },
  methods: {
    getPermissao: function (requested, param) {
      if (param) {
        return eval(
          "permissionObj." +
          this.$route.name +
          "." +
          requested +
          "(" +
          param +
          ")"
        );
      } else {
        return eval("permissionObj." + this.$route.name + "." + requested);
      }
    },
    fazerLogout: function () {
      const self = this;
      localStorage.removeItem("token");
      self.$router.push("/login");
      location.reload();
    },
    go: function (routeName, routeParams) {
      const self = this;
      if (this.$router.currentRoute.name != routeName) {
        if (!routeParams) {
          self.$router.push({ name: routeName });
        } else {
          self.$router.push({ name: routeName, params: routeParams });
        }
      }
    },
  },
};
</script>
