<template>
  <v-container>
    <v-layout align-center>
      <v-flex xs10>
        <h3 class="secondary--text">Invoices</h3>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex xs12 md4 text-xs-right>
        <v-text-field label="Pesquisar" v-model="filtro" color="secondary" append-icon="search"></v-text-field>
      </v-flex>
      <v-flex xs1 text-xs-right>
        <v-tooltip color="secondary" bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" slot="activator" dark small color="secondary" @click="go('CadastroInvoicePrivado')"
              depressed>
              <v-icon>add</v-icon>
            </v-btn>
          </template>
          <span>Adicionar um novo Invoice</span>
        </v-tooltip>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs12 lg3>
        <v-card color="purple darken-2" class="white--text">
          <v-card-title primary-title>
            <div>
              <div class="headline">Saldo CC</div>
              <span>R$ </span>
              <span class="subheading mr-2">{{ customFormatComputed.parseNumber(dashboard.sdpd / 100) }}</span>
              <br />
            </div>
          </v-card-title>
        </v-card>
      </v-flex>
      <v-flex xs12 lg3>
        <v-card color="blue-grey darken-1" class="white--text">
          <v-card-title primary-title>
            <div>
              <div class="headline">Saldo Utilizado</div>
              <span>R$ </span>
              <span class="subheading mr-2">{{ customFormatComputed.parseNumber(dashboard.saldoUtilizado / 100)
                }}</span>
              <br />
            </div>
          </v-card-title>
        </v-card>
      </v-flex>
      <v-flex xs12 lg3>
        <v-card color="cyan darken-2" class="white--text">
          <v-card-title primary-title>
            <div>
              <div class="headline">Saldo em Uso</div>
              <span>R$: </span>
              <span class="subheading mr-2">{{ customFormatComputed.parseNumber(dashboard.saldoEmUso / 100) }}</span>
              <br />
            </div>
          </v-card-title>
        </v-card>
      </v-flex>
      <v-flex xs12 lg3>
        <v-card color="blue darken-2" class="white--text">
          <v-card-title primary-title>
            <div>
              <div class="headline">Diff CC / Em Cartões</div>
              <span>R$: </span>
              <span class="subheading mr-2">{{ customFormatComputed.parseNumber((dashboard.sdpd - dashboard.saldoEmUso)
          /
          100)
                }}</span>
              <br />
            </div>
          </v-card-title>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout justify-space-around row wrap>
      <v-flex xs12>
        <v-card class="default-border" flat text>
          <v-data-table :headers="headers" :items="listaModelo" class="elevation-1 table-motoristas" :search="filtro"
            :sort-by="['dataInclusao']" :sort-desc="[true]" :items-per-page="20" :footer-props="{
          itemsPerPageAllText: 'Todos',
          itemsPerPageText: 'Itens por página',
        }">
            <template v-slot:[`item.valorUsavel`]="{ item }">
              {{
          "R$ " + customFormatComputed.parseNumber(item.valorUsavel / 100)
        }}
            </template>
            <template v-slot:[`item.valorFinal`]="{ item }">
              {{
          "R$ " + customFormatComputed.parseNumber(item.valorFinal / 100)
        }}
            </template>
            <template v-slot:[`item.ativo`]="{ item }">
              {{ item.ativo ? "Ativo" : "Inativo" }}
            </template>
            <template v-slot:[`item.dataInclusao`]="{ item }">
              {{
          customFormatComputed.toLocaleDateTimeString(item.dataInclusao)
        }}
            </template>
            <template v-slot:[`item.controle`]="{ item }">
              <v-tooltip color="secondary" bottom>
                <template v-slot:activator="{ on }">
                  <td>
                    <v-btn small depressed fab v-on="on" color="secondary" class="ma-2 px-1 icon-edit"
                      @click="go('CadastroInvoicePrivado', { id: cipherComputed.encrypt(item.idInvoice) })">
                      <v-icon style="font-size: 16px">edit</v-icon>
                    </v-btn>
                  </td>
                </template>
                <span>Editar Invoice {{ item.idInvoice }}</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
    <v-snackbar :timeout="3200" :color="`${snackbarModel.sucesso ? 'success' : 'error'}`"
      v-model="snackbarModel.mostrar" bottom multi-line>
      {{ snackbarModel.text }}
      <v-btn dark text @click.native="snackbarModel.mostrar = false">Fechar</v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import cipher from "@/helpers/cipher.js";
import customFormat from "@/helpers/custom-format.js";

export default {
  name: "Invoices",
  data: () => ({
    snackbarModel: {
      mostrar: false,
      sucesso: false,
      text: "",
    },
    dashboard: {
      sdpd: 0,
      saldoUtilizado: 0,
      saldoEmUso: 0
    },
    filtro: "",
    headers: [
      { text: "Usuário", value: "usuario.nome" },
      { text: "Valor Usavel", value: "valorUsavel" },
      { text: "Valor Final", value: "valorFinal" },
      { text: "Pagamento Taxa", value: "tipoPagamentoTaxa.nome" },
      { text: "Status", value: "ativo" },
      { text: "Data Inclusão", value: "dataInclusao" },
      { text: "", value: "controle", sortable: false }
    ],
    listaModelo: [],
  }),
  mounted: function () {
    this.buscaSaldo()
    this.buscar()
    this.externalSnackbarMessage()
  },
  computed: {
    customFormatComputed: function () {
      return customFormat;
    },
    cipherComputed: function () {
      return cipher;
    },
  },
  methods: {
    buscaSaldo() {
      const self = this;
      self.$http
        .get(
          `${process.env.VUE_APP_URL_API}/portal/busca/saldo`,
          {
            headers: {
              Authorization:
                `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then(function (response) {
          self.dashboard.sdpd = response.data;
        });
    },
    buscar: function () {
      const self = this;

      self.$store.commit("startLoading");
      self.$http
        .get(`${process.env.VUE_APP_URL_API}/invoice/busca/`, {
          headers: {
            Authorization:
              `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then(function (response) {
          self.listaModelo = response.data;
          self.bindCard()
          self.$store.commit("endLoading");
        }).catch(function (error) {
          console.log(error.response.data)
          self.$store.commit("endLoading");
        });
    },
    bindCard() {
      const self = this;
      self.listaModelo.forEach(e => {
        if (e.ativo) {
          self.dashboard.saldoEmUso = self.dashboard.saldoEmUso + e.valorFinal
        }
        else {
          self.dashboard.saldoUtilizado = self.dashboard.saldoUtilizado + e.valorFinal
        }
      });
    },
    externalSnackbarMessage: function () {
      const self = this;
      if (self.$route.params.snackbarMostrar) {
        self.snackbarModel.sucesso = self.$route.params.snackbarSucesso;
        self.snackbarModel.text = self.$route.params.snackbarText;
        self.snackbarModel.mostrar = self.$route.params.snackbarMostrar;
      }
    },
    go: function (routeName, routeParams) {
      const self = this;
      if (!routeParams) {
        self.$router.push({ name: routeName });
      } else {
        self.$router.push({ name: routeName, params: routeParams });
      }
    }
  },
};
</script>