<template>
    <v-container fluid fill-height>
        <v-layout justify-center>
            <v-flex xs12 sm8>
                <v-card class="elevation-12 default-border">
                    <v-toolbar color="secondary">
                        <v-toolbar-title><b>Resgate seu QRDidndin</b></v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-container grid-list-lg>
                            <v-layout row wrap>
                                <v-flex xs12 md6>
                                    <qrcode-vue :value="url" size="250" level="H"></qrcode-vue>
                                    <v-btn tile icon @click="utilsComputed.copy(url)"><v-icon
                                        style="font-size: 26px">mdi-content-copy</v-icon></v-btn>
                                </v-flex>
                                <v-flex xs12 md6>
                                    <v-label>Insira a chave do resgate recebida por e-mail</v-label>
                                    <v-text-field v-model="chaveResgate" color="secondary" type="text" required
                                        :disabled="chaveResgateOk">
                                        <v-icon v-if="chaveResgateOk" large slot="append"
                                            color="green">mdi-check-circle</v-icon>
                                    </v-text-field>
                                    <v-btn id="validarChaveResgate" color="secondary" class="default-border" depressed
                                        @click="validarCodigo" v-if="!chaveResgateOk">Verificar chave resgate</v-btn>

                                    <div v-if="chaveResgateOk">
                                        <v-form v-model="formularioCadastroValido" ref="formularioCadastroRef">
                                            <v-container grid-list-lg>
                                                <v-layout row wrap>
                                                    <v-flex xs12>
                                                        <v-combobox v-model="resgataCartaoIdInvoice"
                                                            :items="listInvoice" label="Invoice" :rules="regras.branco"
                                                            prepend-icon="mdi-cash-sync" item-text="nome"
                                                            item-value="idInvoice" required return-object></v-combobox>
                                                    </v-flex>
                                                    <v-flex xs12>
                                                        <v-radio-group v-model="tipoChave" row>
                                                            <v-radio label="Email" value="email"></v-radio>
                                                            <v-radio label="CPF" value="cpf"></v-radio>
                                                            <v-radio label="Telefone" value="telefone"></v-radio>
                                                            <v-radio label="Aleatória" value="evp"></v-radio>
                                                        </v-radio-group>
                                                        <div v-if="tipoChave === 'email'">
                                                            <v-text-field v-model="cadastroModel.chavePix"
                                                                clear-icon="mdi-close-circle" label="Chave PIX Email"
                                                                required :rules="regras.email">
                                                            </v-text-field>
                                                        </div>
                                                        <div v-else-if="tipoChave === 'cpf'">
                                                            <v-text-field v-model="cadastroModel.chavePix"
                                                                clear-icon="mdi-close-circle" label="Chave PIX CPF"
                                                                required v-mask="'###.###.###-##'" :rules="regras.cpf">
                                                            </v-text-field>
                                                        </div>
                                                        <div v-else-if="tipoChave === 'telefone'">
                                                            <v-text-field v-model="cadastroModel.chavePix"
                                                                clear-icon="mdi-close-circle" label="Chave PIX Telefone"
                                                                v-mask="'(##)#####-####'" required
                                                                :rules="regras.telefone">
                                                            </v-text-field>
                                                        </div>
                                                        <div v-else-if="tipoChave === 'evp'">
                                                            <v-text-field v-model="cadastroModel.chavePix"
                                                                clear-icon="mdi-close-circle"
                                                                label="Chave PIX Aleatória" required
                                                                :rules="regras.evp">
                                                            </v-text-field>
                                                        </div>
                                                        <v-btn color="secondary" class="default-border" depressed
                                                            :disabled="disableBtnValidar || !formValidPix"
                                                            @click="buscarChave">Validar</v-btn>
                                                    </v-flex>
                                                </v-layout>
                                            </v-container>
                                        </v-form>
                                        <v-flex xs12>Nome: {{ pixModel.account.name }}</v-flex>
                                        <v-flex xs12>CPF/CNPJ: {{ pixModel.account.documentNumber }}</v-flex>
                                        <v-flex xs12>Banco: {{ pixModel.bank.shortName }}</v-flex>
                                        <v-flex xs12>Tipo: {{ pixModel.type.toUpperCase() }}</v-flex>

                                    </div>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn id="finalizarFecha" color="secondary" class="default-border" depressed
                            @click="finalizar()" :disabled="disableBtnFinalizar || !formValid">Resgatar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
        <v-snackbar :timeout="5000" :color="`${snackbarModel.sucesso ? 'success' : 'error'}`" bottom multi-line
            v-model="snackbarModel.mostrar">
            {{ snackbarModel.text }}
        </v-snackbar>
    </v-container>
</template>

<script>
import cipher from "@/helpers/cipher.js";
import utils from "@/helpers/utils.js";
import QrcodeVue from "qrcode.vue";
import customFormat from "@/helpers/custom-format.js";

export default {
    name: "ResgateCartao",
    components: {
        QrcodeVue,
    },
    data: () => ({
        url: "",
        disableBtnValidar: false,
        formularioCadastroValido: false,
        disableBtnFinalizar: false,
        tipoChave: "cpf",
        chaveResgate: "",
        chaveResgateOk: false,
        listInvoice: [],
        cadastroModel: {
            idCartao: null,
            chavePix: null
        },
        pixModel: {
            account: { name: null, documentNumber: null },
            bank: { shortName: null },
            type: "",
            sucesso: false
        },
        resgataCartaoIdInvoice: null,
        resgataCartaoModel: {
            idInvoice: null,
            key: null,
            chaveResgate: null,
            idCartao: null,
        },
        snackbarModel: {
            mostrar: false,
            text: "",
            sucesso: false,
        },
        regras: {
            branco: [(v) => !!v || "Você não pode deixar este campo em branco"],
            email: [
                (v) => !!v || "Você não pode deixar este campo em branco",
                (v) =>
                    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                    "Você precisa informar um e-mail válido",
            ],
            cpf: [
                (v) => !!v || "Você não pode deixar este campo em branco",
                (v) => !!v && v.length == 14 || "Valor inserido é muito curto.",
            ],
            telefone: [
                (v) => !!v || "Você não pode deixar este campo em branco",
                (v) => !!v && v.length == 14 || "Valor inserido é muito curto.",
            ],
            evp: [
                (v) => !!v || "Você não pode deixar este campo em branco",
                (v) => !!v && v.length == 36 || "Valor inserido é muito curto.",
            ],

        }
    }),
    computed: {
        customFormatComputed: function () {
            return customFormat;
        },
        formValid: function () {
            return this.formularioCadastroValido && this.pixModel.sucesso;
        },
        formValidPix: function () {
            return this.formularioCadastroValido;
        },
        cipherComputed: function () {
            return cipher;
        },
        utilsComputed: function () {
            return utils;
        }
    },
    mounted: function () {
        const self = this

        if (self.utilsComputed.isUUID(self.$route.params.id)) {
            window.location.href = location.protocol +
                "//" +
                location.host +
                "/resgate/" + self.cipherComputed.encryptUrl(self.$route.params.id);
        }

        self.url = location.protocol +
            "//" +
            location.host +
            "/resgate/" + encodeURIComponent(self.$route.params.id)

    },
    watch: {
        tipoChave: function () {
            this.cadastroModel.chavePix = "";
        },
    },
    methods: {
        validarCodigo: function () {
            const self = this
            self.$store.commit("startLoading");

            self.$http
                .post(
                    `${process.env.VUE_APP_URL_API}/no-auth/resgate/cartao/dados-resgate`,
                    {
                        idCartao: self.$route.params.id,
                        chaveResgate: self.chaveResgate,
                        key: null
                    }
                )
                .then(function (response) {
                    self.snackbarModel.text = response.data.mensagem;
                    self.snackbarModel.mostrar = true;
                    self.snackbarModel.sucesso = response.data.sucesso;

                    if (response.data.sucesso) {
                        self.chaveResgateOk = true
                        self.cadastroModel.idCartao = response.data.idCartao
                        self.resgataCartaoModel.idCartao = self.$route.params.id,
                            self.resgataCartaoModel.chaveResgate = self.chaveResgate

                        self.chaveResgate = "*********"

                        response.data.listInvoice.forEach(e => {
                            if (e.idTipoPagamentoTaxa == 2) {
                                self.listInvoice.push({
                                    idInvoice: e.idInvoice,
                                    nome: "Liquido R$ " + self.customFormatComputed.parseNumber(e.valorUsavel / 100) + " - Bruto R$ " + self.customFormatComputed.parseNumber(e.valorFinal / 100) + " - Taxa R$" + self.customFormatComputed.parseNumber((e.valorFinal - e.valorUsavel) / 100)
                                })
                            }
                            else {
                                self.listInvoice.push({
                                    idInvoice: e.idInvoice,
                                    nome: "Liquido R$ " + self.customFormatComputed.parseNumber(e.valorUsavel / 100) + " - Sem Taxa"
                                })
                            }
                        });


                    }
                    self.$store.commit("endLoading");
                })
                .catch(function () {
                    self.$store.commit("endLoading");
                });
        },
        buscarChave: function () {
            const self = this;
            if (
                this.$route.params.id
            ) {
                let key = self.cadastroModel.chavePix
                switch (self.tipoChave) {
                    case "cpf":
                        key = self.cadastroModel.chavePix.replaceAll(".", "").replace("-", "")
                        break;
                    case "telefone":
                        key = "+55" + self.cadastroModel.chavePix.replace("(", "").replace(")", "").replace("-", "")
                        break;
                }
                self.$store.commit("startLoading");
                self.$http
                    .get(
                        `${process.env.VUE_APP_URL_API}/no-auth/resgate/busca/chave/` + key
                    )
                    .then(function (response) {
                        const resposta = response.data;
                        self.$store.commit("endLoading");

                        if (resposta.sucesso) {
                            self.pixModel = response.data
                            self.disableBtnValidar = true
                            self.resgataCartaoModel.key = resposta.key
                        } else {
                            self.snackbarModel.text = resposta.mensagem
                            self.snackbarModel.sucesso = false
                            self.snackbarModel.mostrar = true
                        }
                    }).catch(function (error) {
                        self.$store.commit("endLoading");
                        self.snackbarModel.text = "Erro em buscar a chave PIX, verifique e tente novamente...";
                        console.log(error.response.data)
                        self.snackbarModel.sucesso = false;
                        self.snackbarModel.mostrar = true;
                    });
            }
        },
        finalizar: function () {
            const self = this;
            self.$store.commit("startLoading");
            if (self.$refs.formularioCadastroRef.validate()) {
                self.disableBtnFinalizar = true
                self.resgataCartaoModel.idInvoice = self.resgataCartaoIdInvoice.idInvoice
                if (self.$route.params.id) {
                    self.$http
                        .post(
                            `${process.env.VUE_APP_URL_API}/no-auth/resgate/finaliza`,
                            self.resgataCartaoModel
                        )
                        .then(function (response) {
                            const data = response.data;
                            if (data.sucesso) {
                                self.snackbarModel.text = data.mensagem
                                self.snackbarModel.mostrar = true
                                self.snackbarModel.sucesso = data.sucesso
                            } else {
                                self.snackbarModel.text = data.mensagem;
                                self.snackbarModel.mostrar = true;
                                self.snackbarModel.sucesso = data.sucesso;
                                self.disableBtnFinalizar = true;
                            }
                            self.$store.commit("endLoading");
                        })
                        .catch(function (error) {
                            console.log(error.data);
                            self.snackbarModel.text = error.response.data.mensagem;
                            self.snackbarModel.sucesso = false;
                            self.snackbarModel.mostrar = true;
                            self.disableBtnFinalizar = true;
                            self.$store.commit("endLoading");
                        });
                }
            }
        },
        externalSnackbarMessage: function () {
            const self = this;
            if (self.$route.params.snackbarMostrar) {
                self.snackbarModel.sucesso = self.$route.params.snackbarSucesso;
                self.snackbarModel.text = self.$route.params.snackbarText;
                self.snackbarModel.mostrar = self.$route.params.snackbarMostrar;
            }
        }
    }
};
</script>