<template>
  <v-container>
    <v-layout align-center>
      <v-flex xs10>
        <h3 class="secondary--text">Cartões</h3>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex xs12 md4 text-xs-right>
        <v-text-field label="Pesquisar" v-model="filtro" color="secondary" append-icon="search"></v-text-field>
      </v-flex>
      <v-flex xs1 text-xs-right>
        <v-tooltip color="secondary" bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" slot="activator" dark small color="secondary" @click="abrirModalCartaoCode" depressed>
              <v-icon>mdi-tab-search</v-icon>
            </v-btn>
          </template>
          <span>Busca Codigo do Cartão por Link</span>
        </v-tooltip>
      </v-flex>
    </v-layout>
    <v-layout justify-space-around row wrap>
      <v-flex xs12>
        <v-card class="default-border" flat text>
          <v-data-table :headers="headers" :items="listaModelo" class="elevation-1 table-motoristas" :search="filtro"
            :sort-by="['dataInclusao']" :sort-desc="[true]" :items-per-page="20" :footer-props="{
          itemsPerPageAllText: 'Todos',
          itemsPerPageText: 'Itens por página',
        }">
            <template v-slot:[`item.idCartao`]="{ item }">
              {{ item.idCartao }}
              <v-btn tile icon @click="utilsComputed.copy(item.idCartao)"><v-icon
                  style="font-size: 16px">mdi-content-copy</v-icon></v-btn>
              <v-btn tile icon @click="utilsComputed.gotoLinkNewTab(geraUrlCartao(item.idCartao))"><v-icon
                  style="font-size: 16px">mdi-open-in-new</v-icon></v-btn>
            </template>
            <template v-slot:[`item.ativo`]="{ item }">
              {{ item.ativo ? "Ativo" : "Inativo" }}
            </template>
            <template v-slot:[`item.dataInclusao`]="{ item }">
              {{
          customFormatComputed.toLocaleDateTimeString(item.dataInclusao)
        }}
            </template>
            <template v-slot:[`item.controle`]="{ item }">
              <v-layout justify-center>
                <v-tooltip color="secondary" bottom>
                  <template v-slot:activator="{ on }">
                    <td>
                      <v-btn x-small depressed fab v-on="on" color="secondary" class="ma-2 px-1 icon-edit"
                        @click="abrirModalCartao(item)">
                        <v-icon style="font-size: 16px">mdi-data-matrix</v-icon>
                      </v-btn>
                    </td>
                  </template>
                  <span>Ver QRCode do Cartão {{ item.idCartao }}</span>
                </v-tooltip>
                <v-tooltip color="secondary" bottom>
                  <template v-slot:activator="{ on }">
                    <td>
                      <v-btn x-small depressed fab v-on="on" color="secondary" class="ma-2 px-1 icon-edit"
                        @click="go('CadastroCartao', { id: cipherComputed.encrypt(item.idCartao) })">
                        <v-icon style="font-size: 16px">edit</v-icon>
                      </v-btn>
                    </td>
                  </template>
                  <span>Editar Cartão {{ item.idCartao }}</span>
                </v-tooltip>
                <v-tooltip color="secondary" bottom>
                  <template v-slot:activator="{ on }">
                    <td>
                      <v-btn x-small depressed fab v-on="on" color="secondary" class="ma-2 px-1 icon-edit"
                        @click="abrirModalInvoice(item.idCartao)">
                        <v-icon style="font-size: 16px">mdi-briefcase-eye</v-icon>
                      </v-btn>
                    </td>
                  </template>
                  <span>Ver Invoice do {{ item.idCartao }}</span>
                </v-tooltip>
              </v-layout>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
      <template>
        <v-dialog v-model="dialogInvoices" persistent>
          <v-card>
            <v-flex xs12>
              <v-card-title>
                <span class="text-h5">Invoices</span>
              </v-card-title>
              <v-card-text>
                <v-data-table :headers="headersInvoice" :items="listaInvoiceModelo" class="elevation-1 table-motoristas"
                  :search="filtro" :sort-by="['dataInclusao']" :sort-desc="[true]" :items-per-page="20" :footer-props="{
          itemsPerPageAllText: 'Todos',
          itemsPerPageText: 'Itens por página',
        }">
                  <template v-slot:[`item.valorUsavel`]="{ item }">
                    {{
          "R$ " + customFormatComputed.parseNumber(item.valorUsavel / 100)
        }}
                  </template>
                  <template v-slot:[`item.ativo`]="{ item }">
                    {{ item.ativo ? "Ativo" : "Inativo" }}
                  </template>
                  <template v-slot:[`item.dataInclusao`]="{ item }">
                    {{
          customFormatComputed.toLocaleDateTimeString(item.dataInclusao)
        }}
                  </template>
                </v-data-table>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary" text @click="closeInvoice()">
                  Fechar
                </v-btn>
              </v-card-actions>
            </v-flex>
          </v-card>
        </v-dialog>
      </template>
      <template>
        <v-dialog v-model="dialogCartao" persistent>
          <v-card>
            <v-flex xs12>
              <v-card-title>
                <span class="text-h5">Cartão</span>
              </v-card-title>
              <v-card-text>
                <v-container grid-list-lg>
                  <v-layout row wrap>
                    <v-flex xs12 md6>
                      <qrcode-vue :value="cadastroCartaoModel.url" size="300" level="H"></qrcode-vue>
                    </v-flex>
                    <v-flex xs12 md6>
                      <v-flex xs12>Codigo do Cartão: {{ cadastroCartaoModel.idCartao }} <v-btn tile icon
                          @click="utilsComputed.copy(cadastroCartaoModel.idCartao)"><v-icon
                            style="font-size: 16px">mdi-content-copy</v-icon></v-btn></v-flex>
                      <v-flex xs12>Ativo: {{ cadastroCartaoModel.ativo ? "Sim" : "Não" }}</v-flex>
                      <v-flex xs12>Email: {{ cadastroCartaoModel.email }}</v-flex>

                      <v-flex xs12>Link Cartão: <v-btn tile icon
                          @click="utilsComputed.gotoLinkNewTab(cadastroCartaoModel.url)"><v-icon
                            style="font-size: 16px">mdi-open-in-new</v-icon></v-btn>
                        <v-btn tile icon @click="utilsComputed.copy(cadastroCartaoModel.url)"><v-icon
                            style="font-size: 16px">mdi-content-copy</v-icon></v-btn></v-flex>
                      <v-flex xs12>Data Inclusão: {{
          customFormatComputed.toLocaleDateTimeString(cadastroCartaoModel.dataInclusao) }}</v-flex>
                      <v-flex xs12><v-btn tile @click="reenvioEmail(cadastroCartaoModel)">Reenvio de
                          Email de
                          regate</v-btn></v-flex>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary" text @click="closeCartao()">
                  Fechar
                </v-btn>
              </v-card-actions>
            </v-flex>
          </v-card>
        </v-dialog>
      </template>
      <template>
        <v-dialog v-model="dialogInvoices" persistent>
          <v-card>
            <v-flex xs12>
              <v-card-title>
                <span class="text-h5">Invoices do Cartão</span>
              </v-card-title>
              <v-card-text>
                <v-data-table :headers="headersInvoice" :items="listaInvoiceModelo" class="elevation-1 table-motoristas"
                  :search="filtro" :sort-by="['dataInclusao']" :sort-desc="[true]" :items-per-page="20" :footer-props="{
          itemsPerPageAllText: 'Todos',
          itemsPerPageText: 'Itens por página',
        }">
                  <template v-slot:[`item.valorUsavel`]="{ item }">
                    {{
          "R$ " + customFormatComputed.parseNumber(item.valorUsavel / 100)
        }}
                  </template>
                  <template v-slot:[`item.ativo`]="{ item }">
                    {{ item.ativo ? "Ativo" : "Inativo" }}
                  </template>
                  <template v-slot:[`item.dataInclusao`]="{ item }">
                    {{
          customFormatComputed.toLocaleDateTimeString(item.dataInclusao)
        }}
                  </template>
                </v-data-table>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary" text @click="closeInvoice()">
                  Fechar
                </v-btn>
              </v-card-actions>
            </v-flex>
          </v-card>
        </v-dialog>
      </template>
      <template>
        <v-dialog v-model="dialogCartaoCode" persistent>
          <v-card>
            <v-flex xs12>
              <v-card-title>
                <span class="text-h5">DeCodifica Link Cartão</span>
              </v-card-title>
              <v-card-text>
                <v-container grid-list-lg>
                  <v-layout row wrap>
                    <v-flex xs12>
                      <v-text-field v-model="dcodeLink" color="secondary" prepend-icon="mdi-link-variant"
                        label="Link Cartão" type="text" required></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                      <p><span class="subtitle-1">{{ idCartaoUUID }}</span><v-btn tile icon :disabled="cartaoCode"
                          @click="utilsComputed.copy(idCartaoUUID)"><v-icon
                            style="font-size: 16px">mdi-content-copy</v-icon></v-btn></p>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-btn color="secondary" text @click="closeCartaoCode()">
                  Fechar
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="secondary" class="default-border" depressed @click="codeLinkCartao()"
                  :disabled="dcodeLink == ''">Decodificar</v-btn>
              </v-card-actions>
            </v-flex>
          </v-card>
        </v-dialog>
      </template>
    </v-layout>
    <v-snackbar :timeout="3200" :color="`${snackbarModel.sucesso ? 'success' : 'error'}`"
      v-model="snackbarModel.mostrar" bottom multi-line>
      {{ snackbarModel.text }}
      <v-btn dark text @click.native="snackbarModel.mostrar = false">Fechar</v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import cipher from "@/helpers/cipher.js";
import customFormat from "@/helpers/custom-format.js";
import QrcodeVue from "qrcode.vue";
import utils from "@/helpers/utils.js";

export default {
  name: "Cartoes",
  components: {
    QrcodeVue,
  },
  data: () => ({
    snackbarModel: {
      mostrar: false,
      sucesso: false,
      text: "",
    },
    dialogInvoices: false,
    dialogCartao: false,
    dialogCartaoCode: false,
    dcodeLink: "",
    idCartaoUUID: "",
    cartaoCode: true,
    cadastroCartaoModel: {
      idCartao: null,
      usuario: null,
      ativo: false,
      dataInclusao: null,
      url: ""
    },
    filtro: "",
    headers: [
      { text: "IdCartao", value: "idCartao" },
      { text: "Usuário", value: "usuario.nome" },
      { text: "Email", value: "email" },
      { text: "Status", value: "ativo" },
      { text: "Data Inclusão", value: "dataInclusao" },
      { text: "", value: "controle", sortable: false }
    ],
    headersInvoice: [
      { text: "Usuário", value: "usuario.nome" },
      { text: "Valor", value: "valorUsavel" },
      { text: "Pagamento Taxa", value: "tipoPagamentoTaxa.nome" },
      { text: "Status", value: "ativo" },
      { text: "Data Inclusão", value: "dataInclusao" },
    ],
    listaModelo: [],
    listaInvoiceModelo: [],
  }),
  mounted: function () {
    this.buscar()
    this.externalSnackbarMessage()
  },
  computed: {
    customFormatComputed: function () {
      return customFormat;
    },
    cipherComputed: function () {
      return cipher;
    },
    utilsComputed: function () {
      return utils;
    }
  },
  methods: {
    codeLinkCartao() {
      const self = this;

      try {
        const list = self.dcodeLink.split("/");
        const codigo = list[list.length - 1]

        const final = cipher.decrypt(decodeURIComponent(codigo));

        if (self.utilsComputed.isUUID(final)) {
          self.idCartaoUUID = final
          self.cartaoCode = false;
        } else {
          self.idCartaoUUID = "Erro ao decodificar"
          self.cartaoCode = true;
        }
      } catch (error) {
        console.log(error)
        self.cartaoCode = true;
        self.idCartaoUUID = "Erro ao decodificar"
      }

    },
    abrirModalInvoice(idcartao) {
      const self = this;
      self.buscarInvoice(idcartao);
      self.dialogInvoices = true;
    },
    abrirModalCartao(cartao) {
      const self = this;
      self.cadastroCartaoModel = cartao;

      self.cadastroCartaoModel.url = self.geraUrlCartao(self.cadastroCartaoModel.idCartao)

      self.dialogCartao = true;
    },
    abrirModalCartaoCode() {
      const self = this;
      self.dialogCartaoCode = true;
    },
    geraUrlCartao(idCartao) {
      const self = this;
      return location.protocol +
        "//" +
        location.host +
        "/resgate/" +
        self.cipherComputed.encryptUrl(idCartao);
    },
    closeInvoice() {
      const self = this;
      self.dialogInvoices = false;
    },
    closeCartao() {
      const self = this;
      self.dialogCartao = false;
    },
    closeCartaoCode() {
      const self = this;
      self.dialogCartaoCode = false;
    },
    buscarInvoice(idcartao) {
      const self = this;

      self.$store.commit("startLoading");
      self.$http
        .get(`${process.env.VUE_APP_URL_API}/invoice/busca/cartao/` + idcartao, {
          headers: {
            Authorization:
              `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then(function (response) {
          self.listaInvoiceModelo = response.data;

          self.$store.commit("endLoading");
        }).catch(function (error) {
          console.log(error.response.data)
          self.$store.commit("endLoading");
        });
    },
    reenvioEmail(cartao) {
      const self = this;

      self.$store.commit("startLoading");
      self.$http
        .post(
          `${process.env.VUE_APP_URL_API}/cartao/reenvia-email`,
          cartao,
          {
            headers: {
              Authorization:
                `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then(function (response) {
          const data = response.data;
          self.$store.commit("endLoading");
          self.snackbarModel.text = data.mensagem;
          self.snackbarModel.mostrar = true;
          self.snackbarModel.sucesso = data.sucesso;
          self.disableBtnFinalizar = data.sucesso;
        })
        .catch(function (error) {
          self.$store.commit("endLoading");
          self.snackbarModel.text = error.response.data.mensagem;
          self.snackbarModel.sucesso = false;
          self.snackbarModel.mostrar = true;
          self.disableBtnFinalizar = false;
        });
    },
    buscar() {
      const self = this;

      self.$store.commit("startLoading");
      self.$http
        .get(`${process.env.VUE_APP_URL_API}/cartao/busca/`, {
          headers: {
            Authorization:
              `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then(function (response) {
          self.listaModelo = response.data;
          self.$store.commit("endLoading");
        }).catch(function (error) {
          console.log(error.response.data)
          self.$store.commit("endLoading");
        });
    },
    externalSnackbarMessage: function () {
      const self = this;
      if (self.$route.params.snackbarMostrar) {
        self.snackbarModel.sucesso = self.$route.params.snackbarSucesso;
        self.snackbarModel.text = self.$route.params.snackbarText;
        self.snackbarModel.mostrar = self.$route.params.snackbarMostrar;
      }
    },
    go: function (routeName, routeParams) {
      const self = this;
      if (!routeParams) {
        self.$router.push({ name: routeName });
      } else {
        self.$router.push({ name: routeName, params: routeParams });
      }
    }
  },
};
</script>